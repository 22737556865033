<template>
    <section :id="cmsBlock.anchorTag" :class="cmsBlock.partnersStyle">
        <h3 v-if="localize(cmsBlock.title)">{{ localize(cmsBlock.title) }}</h3>
        <div v-if="cmsBlock.description" class="text second-grid" v-html="md(localize(cmsBlock.description))"></div>
        <div class="partners-wrapper">
            <div v-for="(partner, index) in cmsBlock.partners.data" :key="partner.id" class="partner">
                <a v-if="partner.attributes.homepageLink !== ''" :href="partner.attributes.homepageLink" target="_blank">
                    <picture v-if="srcsets[index] && partner.attributes.image">
                        <template v-for="[format, set] of Object.entries(srcsets[index])">
                            <source v-if="format !== originalFormats[index]" :srcset="set" sizes="30vw"
                                :type="'image/' + format.slice(1)">
                        </template>
                        <img v-if="partner.attributes.image && originalFormats[index].slice(1) !== 'svg'" loading="lazy"
                            decoding="async" v-bind:srcset="srcsets[index][originalFormats[index]]"
                            :type="'image/' + originalFormats[index].slice(1)" sizes="30vw"
                            :alt="localize(partner.attributes.image).data.attributes.alternativeText">
                        <img v-if="partner.attributes.image && originalFormats[index].slice(1) === 'svg'"
                            v-bind:src="srcsets[index][originalFormats[index]]" :type="'image/svg+xml'"
                            :alt="localize(partner.attributes.image).data.attributes.alternativeText">
                    </picture>
                    <img v-if="!srcsets[index] && partner.attributes.image" :src="getPartnerImage(partner)"
                        :alt="localize(partner.attributes.image).data.attributes.alternativeText">
                    <h6 v-if="!partner.attributes.image" class="va-center fake-center">{{ localize(partner.text) }}</h6>
                </a>
                <template v-else>
                    <picture v-if="srcsets[index] && partner.attributes.image">
                        <template v-for="[format, set] of Object.entries(srcsets[index])">
                            <source v-if="format !== originalFormats[index]" :srcset="set" sizes="30vw"
                                :type="'image/' + format.slice(1)">
                        </template>
                        <img v-if="partner.attributes.image && originalFormats[index].slice(1) !== 'svg'" loading="lazy"
                            decoding="async" v-bind:srcset="srcsets[index][originalFormats[index]]"
                            :type="'image/' + originalFormats[index].slice(1)" sizes="30vw"
                            :alt="localize(partner.attributes.image).data.attributes.alternativeText">
                        <img v-if="partner.attributes.image && originalFormats[index].slice(1) === 'svg'"
                            v-bind:src="srcsets[index][originalFormats[index]]" :type="'image/svg+xml'"
                            :alt="localize(partner.attributes.image).data.attributes.alternativeText">
                    </picture>
                    <img v-if="!srcsets[index] && partner.attributes.image" :src="getPartnerImage(partner)"
                        :alt="localize(partner.attributes.image).data.attributes.alternativeText">
                    <h6 v-if="!partner.attributes.image" class="va-center fake-center">{{ localize(partner.text) }}</h6>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'PartnersBlock',

    props: [
        'cmsBlock'
    ],

    computed: {
        srcsets() {
            let srcsets = []
            for (const partner of this.cmsBlock.partners.data) {
                srcsets.push(this.getImageSrcsets(this.localize(partner.attributes.image).data.attributes))
            }
            return srcsets
        },

        hasWebp() {
            let hasWebps = []
            for (const srcset of this.srcsets) {
                if (!srcset) {
                    hasWebps.push(false)
                    continue
                }
                hasWebps.push(srcset['.webp'] ? true : false)
            }
            return hasWebps
        },

        originalFormats() {
            let originalFormats = []
            for (const srcset of this.srcsets) {
                if (!srcset) {
                    originalFormats.push(null)
                    continue
                }
                for (const [format, _] of Object.entries(srcset)) {
                    if (format !== '.webp') {
                        originalFormats.push(format)
                    }
                }
            }

            return originalFormats
        },
    },

    methods: {
        getPartnerImage(partner) {
            if (this.localize(partner.attributes.image)) {
                return this.getImageUrl(this.localize(partner.attributes.image).data.attributes.url)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.partners-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
    width: calc(100% - 2 * getSpacing('padding-default-horizontal', 'desktop'));
    max-width: $container-width;
    margin: 0 auto;
    padding: 0 getSpacing('padding-default-horizontal', 'desktop');


    @include breakpoint('tablet') {
        width: calc(100% - 2 * getSpacing('padding-default-horizontal', 'tablet'));
        padding: 0 getSpacing('padding-default-horizontal', 'tablet');
    }

    @include breakpoint('mobile') {
        width: calc(100% - 2 * getSpacing('padding-default-horizontal', 'mobile'));
        padding: 0 getSpacing('padding-default-horizontal', 'mobile');
    }

    .partner {
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 150px;
        height: 100px;
        flex-basis: 0;
        flex: 1 1 0px;

        opacity: .7;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        -webkit-transition: .5s all linear;
        -o-transition: .5s all linear;
        transition: .5s all linear;

        &:hover {
            opacity: 1;
            -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
        }

        img,
        h6 {
            width: 100%;
            height: 70px;
            margin: 0px auto 0 auto;
            object-fit: contain;
        }
    }
}
</style>
